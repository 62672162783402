<template lang="pug">
.buscar-paciente

        Cadastro(:dialogCadastro='dialogCadastro' :label='label' :waitingPaciente='waitingPaciente' :bpModel='model' @close='dialogCadastro=false' :medEmpresa='medEmpresa')

        form.form-header(v-if='!hideSearchForm')

            label.form-label {{ label || 'Paciente' }}:
            .p-grid.p-fluid
                .p-col-12(v-if='waiting')
                    ProgressBar(mode="indeterminate")
                .p-col-12(v-else-if='! list.length')
                    .p-grid.p-fluid
                        .p-col-3
                            Dropdown(
                                v-model='filtro.selected.by'
                                :options='filtro.options'
                                optionLabel='text'
                                optionValue='value'
                                dataKey='value'
                            )
                        .p-col-9
                            .p-inputgroup
                                InputMask(v-if='filtro.selected.by == "nr_cpf"' placeholder="CPF" v-model='filtro.selected.value'
                                    :autoClear='false' mask='999.999.999-99' @keydown.enter='buscar()')
                                InputText(v-else v-model='filtro.selected.value' @keyup.enter='buscar()')
                                Button(v-if='!modoEmpresa' icon='jam jam-search' type='button' @click='buscar()')

                        div(v-if='filtro.selected.by == "nm_pessoa_fisica" && modoEmpresa')
                            .p-col-3
                            .p-col-9
                                .p-inputgroup
                                    InputMask(placeholder="Data de Nascimento" v-model='filtro.dt_nascimento.value'
                                        :autoClear='false' mask='99/99/9999' @keydown.enter='buscar()')
                                    Button(icon='jam jam-search' type='button' @click='buscar()')

                        .p-col-12(v-if="!hideCadastro || hideCadastro == undefined" style='padding-top: 0; padding-bottom: 0; margin-top: -4px;')
                            .ta-right
                                a.link-cadastrar(@click='model=null; dialogCadastro=true') Ou clique aqui para cadastrar

                .p-col-12(v-else)
                    .p-inputgroup
                        Button.p-button-danger(v-if='!ocultaLimpar' icon='jam jam-rubber' :label='ocultarNomeLimpar? "": "Limpar" ' type='button' @click='limpar()')
                        Dropdown(
                            v-model='cd_pessoa_fisica'
                            :options='list'
                            optionLabel='label'
                            optionValue='id'
                            dataKey='id'
                            placeholder='- Selecione -'
                            @input='if (! cancelarPopup) dialogCadastro = true'
                        )
                            template(#option='{option}')
                                span(v-if='medEmpresa ? option.cd_titular : option.cd_pai')
                                    b(v-text='"(Dependente) "')
                                span(v-else)
                                    b(v-text='"(Titular) "')
                                span(v-text='option.label')
                    span(v-if='medEmpresa && model?.empresas_associado.length')
                        .p-col-12(style="padding: 0.5rem 0")
                            b Empresa Associada:
                            Dropdown(
                                v-model='titular.empresa_associada_f'
                                :options='model.empresas_associado'
                                optionLabel='empresa.nm_fantasia'
                                dataKey='id'
                                placeholder='- Selecione -'
                            )
                        b Titular e Créditos:
                        .p-inputgroup(v-if='titular?.empresa_associada_f')
                            .p-col-9(style="padding: 0")
                                InputText.dadoTitular(:value='titular.nm_pessoa_fisica' readonly)
                            .p-col-3(style="padding: 0")
                                InputText.ta-right.dadoTitular.credito-medclub(:value='$root.formatPrice(titular.nr_saldo)' readonly)

</template>

<style lang="scss">
    .buscar-paciente {
        .p-progressbar {
            margin: 18px 0;
        }
        .link-cadastrar {
            margin: 0;
            cursor: pointer;
            font-size: 13px;
        }
        .p-inputtext::not(:first-child) {
            border-left: 1px solid;
        }
        .dadoTitular {
			color: #000;
			font-weight: 600;
			background-color: #ddd;
		}
        .credito-medclub {
			color: #3c60ba !important;
		}
    }
    .ea_dialog {
        width: 60%;
    }
</style>

<script>
    import InputText from 'primevue/inputtext'
    import InputMask from 'primevue/inputmask'
    import Dropdown from 'primevue/dropdown'
    import Dialog from 'primevue/dialog'
    import Button from 'primevue/button'
    import ProgressBar from 'primevue/progressbar'
    import Cadastro from './BuscarPaciente-cadastro'

    import { Paciente, MedEmpresa } from './../../middleware'
    import moment from 'moment'
    export default {
        components: { InputText, InputMask, Dropdown, Button, ProgressBar, Cadastro, Dialog },
        created() {
            if(this.modoEmpresa) {
                this.filtro.options = [
                        { text: 'CPF', value: 'nr_cpf' },
                        { text: 'Nome e Data de Nascimento', value: 'nm_pessoa_fisica' }
                    ]
            } else {
                this.filtro.options = [
                        { text: 'CPF', value: 'nr_cpf' },
                        { text: 'Código', value: 'id' },
                        { text: 'Nome', value: 'nm_pessoa_fisica' }
                    ]
            }
        },
        mounted() {
            this.$emit('existe')
        },
        watch: {
            cd_pessoa_fisica: async function(val) {
                this.$emit('change', val)
                if (val) {
                    let find = this.list.find(elem => elem.id == val)
                    if (find) {
                        if(this.medEmpresa){
                            switch (true) {
                                case find.empresas_associado.length == 0 && !find.cd_titular:
                                    this.$toast.error('Cliente sem associação com empresas.')
                                    break;
                                case find.empresas_associado.length > 1:
                                    this.$toast.default('Cliente possui mais de uma Empresa Associada')
                                    break;
                                default:
                                    find.empresa_associada_f = find.empresas_associado[0]
                                    break;
                            }
                        }
                        this.model = find
                        const pai = this.medEmpresa ? 'cd_titular' : 'cd_pai'
                        this.model['responsavel'] = this.list.find(e => e.id == this.model[pai]) ?? this.model[pai]
                        if (! this.label && ! this.cancelarPopup) { this.dialogCadastro = true }
                        if (this.onSelectPessoaFisica) this.onSelectPessoaFisica()
                    } else await this.mudaComPessoaFisica(val)
                this.atualizaTitular(find)
            }
            },
            model: function(val){
                this.$emit('model', val)
            },
            titular: function(val){
                this.$emit('titular', val)
            },
            waiting: function(val) {
                this.$emit('waiting', val)
            },
            'titular.empresa_associada_f': function(val) {
                if (this.medEmpresa && val) {
                    if (!val.empresa.ie_status)
                        this.$toast.error('Empresa suspensa')
                    this.$emit('empresaInativa', val.empresa.ds_motivo_suspensao)
                }
            }
        },
        props: [ 'label', 'somenteTitular', 'somenteIndicador', 'cancelarPopup', 'hideSearchForm' , 'hideCadastro', 'modoEmpresa', 'medEmpresa', 'ocultaLimpar', 'ocultarNomeLimpar'],
        data () {
            return {
                cd_pessoa_fisica: null,
                model: null,
                titular: {
                    empresa_associada_f: null
                },
                list: [],
                filtro: {
                    options: [],
                    selected: { by: 'nr_cpf', value: '' },
                    dt_nascimento: { value: '' }
                },
                dialogCadastro: false,
                dialogEmpresaAssociado: false,
                waiting: false,
                waitingPaciente: false,
                onSelectPessoaFisica: null,
            }
        },
        methods: {
            async buscar (id,prevParams) {
                if (!this.filtro.selected.value.length) {
                    this.$toast.error('Digite um termo para busca', { duration: 3000 })
                    return 0
                }
                if(this.modoEmpresa && this.filtro.selected.by == 'nm_pessoa_fisica'
                    && !this.filtro.dt_nascimento.value.length
                    && !this.filtro.dt_nascimento.value.length) {
                        this.$toast.error('Digite a data de nascimento', { duration: 3000 })
                        return 0
                }
                this.list = []

                let params = this.somenteTitular ? { titular: true } : {}

                if (this.somenteIndicador) {
                    params['indicador'] = true
                    params['ie_agente_ativo'] = true
                }

                if (this.filtro.selected.by == 'nr_cpf') {
                    params.nr_cpf = this.filtro.selected.value.replace(/(\.|-|_)/g, '')
                } else params[this.filtro.selected.by] = this.filtro.selected.value
                this.waiting = true
                if(this.filtro.selected.by == 'nm_pessoa_fisica' && this.modoEmpresa) {
                    params['dt_nascimento'] = moment(this.filtro.dt_nascimento.value, 'DD/MM/YYYY').format('YYYY-MM-DD')
                }
                const idToUse = id
                params = { ...params, ...prevParams }
                const paciente = this.medEmpresa
                                    ? idToUse
                                        ? await MedEmpresa.findFamilia(idToUse,params)
                                        : await MedEmpresa.findAllPacientes(params)
                                    : idToUse
                                        ? await Paciente.findFamilia(idToUse,params)
                                        : await Paciente.findAll(params)

                if (paciente.status == 200) {
                    const data = paciente.data
                    if (data.length) {
                        data.forEach((result) => {
                            if (result.nr_cpf) result.nr_cpf_f = result.nr_cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
                            result.dt_nascimento_f = moment(result.dt_nascimento).format('DD/MM/YYYY')
                            result.ie_sexo_f = result.ie_sexo == 'M' ? 'Masculino' : 'Feminino'
                            result.label = result.nr_cpf ? `${ result.nm_pessoa_fisica } (CPF: ${ result.nr_cpf_f })` :
                                `${ result.nm_pessoa_fisica } (Data nasc.: ${ result.dt_nascimento_f })`
                            if(result.id == id || data.length == 1) {
                                this.cd_pessoa_fisica = result.id
                            }
                            result.responsavel = this.medEmpresa ? result.cd_titular : result.cd_pai

                        })
                        this.list = data
                        this.$emit("busca")
                    } else this.$toast.error('Pesquisa sem resultado', { duration: 3000 })
                    this.waiting = false
                }
            },
            limpar () {
                this.$emit("limpa")
                this.list = []
                this.titular = {
                    empresa_associada_f: null
                }
                this.cd_pessoa_fisica = null
                this.filtro.selected = { by: 'nr_cpf', value: '' }
                this.filtro.dt_nascimento = { value: '' }
                this.model = null
            },
            async mudaComPessoaFisica(cd_pessoa_fisica) {
                if (cd_pessoa_fisica) {
                    this.waitingPaciente = true
                    const response = await Paciente.find(cd_pessoa_fisica)
                        if (response.status === 200) {
                            this.model = response.data
                            if (this.model.nr_cpf) {
                                this.model.nr_cpf_f = this.model.nr_cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
                                this.model.nr_cpf = this.model.nr_cpf_f
                            }
                            this.model.dt_nascimento_f = moment(this.model.dt_nascimento).format('DD/MM/YYYY')
                            this.model.ie_sexo_f = this.model.ie_sexo == 'M' ? 'Masculino' : 'Feminino'
                            this.model.label = this.model.nr_cpf ? `${ this.model.nm_pessoa_fisica } (CPF: ${ this.model.nr_cpf_f })` :
                                `${ this.model.nm_pessoa_fisica } (Data nasc.: ${ this.model.dt_nascimento_f })`
                            if (this.onSelectPessoaFisica) this.onSelectPessoaFisica()
                        }
                        this.waitingPaciente = false
                }
            },
            atualizaTitular (modelo) {
                const props = ['id', 'nm_pessoa_fisica', 'nr_saldo']
                if (this.medEmpresa) {
                    this.titular.empresa_associada_f = modelo.empresa_associada_f;
                    props.forEach(key => {
                        this.titular[key] = modelo?.empresa_associada_f?.titular
                                                    ? modelo.empresa_associada_f.titular[key]
                                                    : key == 'nr_saldo' 
                                                        ? modelo?.empresa_associada_f?.nr_saldo
                                                        : modelo[key]
                    })
                } else 
                    this.titular = modelo.responsavel || modelo
            }
        }
    }
</script>
